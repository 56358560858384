import { memo, type ReactNode, useEffect, useRef, useState } from 'react'

import type { ListingCardCarousel_ListingFragment } from './__generated__/listing-card-carousel.gql'
import styles from '@brand/slots/listing-card/listing-card-scroll-snap-carousel.module.css'
import { ListingCardDetailPageLink } from './listing-card-detail-page-link'
import { ImageWithFallback } from '../../components/image-with-fallback/image-with-fallback'
import { yieldOrContinue } from 'main-thread-scheduling'
import { ScrollSnapCarousel } from '../../components/carousel/scroll-snap-carousel'
import { DIRECTION } from '../../components/carousel/scroll-snap-carousel.const'
import { getCloudinaryUrl } from '../../components/cloud-image/get-cloudinary-url'
import { getAltText } from './listing-card.utils'
import {
  StaticMap,
  StaticStreetView,
} from '../../components/static-map/static-map'
import { markerUrl } from '@brand/detail/const'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { generateSimpleLocationUrl } from '../../components/static-map/static-map-utils'
import { Pulse } from '../../components/pulse/pulse'
import checkStreetViewMetadata from '../../components/static-map/static-map-check-metadata'
import type { MarkerPopup_ListingFragment } from './map-marker/__generated__/marker-popup.gql'

type ListingCardCarouselOwnProps = {
  listingId: string
  shouldUseFirstCarouselImageAsLCP?: boolean
  dataTagSection?: string
  isMobile?: boolean
  onFirstInteraction?: () => void
  onLinkClick: () => void
  disableSwipe?: boolean
  cityState?: string
  buttonStyles?: {
    btnNext?: string
    btnPrev?: string
  }
  carouselClassName?: string
  firstPhoto?: {
    id: string
  } | null
}

type ListingCardCarouselProps = ListingCardCarousel_ListingFragment &
  ListingCardCarouselOwnProps &
  Pick<MarkerPopup_ListingFragment, 'urlPathname'>

export function ListingCardNoPhotosCarouselBase({
  listingId,
  firstPhoto,
  dataTagSection,
  urlPathname,
  isMobile,
  onFirstInteraction,
  onLinkClick,
  isUnpaid,
  name,
  cityState,
  location,
  carouselClassName,
  buttonStyles,
  shouldUseFirstCarouselImageAsLCP: isLCP,
}: ListingCardCarouselProps) {
  const slideRef = useRef(null)
  const firstInteractionRef = useRef<boolean>(true)
  const isFullGalleryActiveMobile =
    useFeatureVariable<number>(['google_street_view', 'mobile_version'], 0) ===
      2 && isMobile
  const isFullGalleryActiveDesktop =
    useFeatureVariable<number>(['google_street_view', 'desktop_version'], 0) ===
      2 && !isMobile
  const isFullGallery = isFullGalleryActiveMobile || isFullGalleryActiveDesktop

  const [isStreetViewAvailable, setStreetViewAvailable] = useState<
    boolean | null
  >(null)

  useEffect(() => {
    const url = generateSimpleLocationUrl(location)
    if (!url) {
      return setStreetViewAvailable(false)
    }
    checkStreetViewMetadata(
      url,
      (data: { metadata?: Record<string, string> | null }) =>
        setStreetViewAvailable(Boolean(data.metadata))
    )
  }, [location])

  if (!urlPathname) return null

  const mapGeneralProps = {
    className: styles.image,
    lat: location?.lat,
    lng: location?.lng,
    name,
  }

  const CardLinkWrapper = ({ children }: { children: ReactNode }) => (
    <ListingCardDetailPageLink
      className={styles.image}
      isMobile={isMobile}
      href={urlPathname}
      onClick={onLinkClick}
    >
      {children}
    </ListingCardDetailPageLink>
  )

  const firstPhotoItem = firstPhoto?.id ? (
    <CardLinkWrapper key="listing-no-photos-first-photo">
      <ImageWithFallback
        id={`listing-card-carousel-${listingId}-${firstPhoto.id}-${0}`}
        width="350"
        height="240"
        priority={isLCP}
        fetchPriority={isLCP ? 'high' : 'low'}
        unoptimized
        src={getCloudinaryUrl(firstPhoto.id, 'lg', {
          isUnpaid: isUnpaid,
        })}
        alt={getAltText(name, 0, cityState)}
        className={styles.image}
        fallbackImageSize="sm"
      />
    </CardLinkWrapper>
  ) : null

  const streetViewItem = (
    <CardLinkWrapper key="listing-no-photos-street-view">
      <StaticStreetView fallbackImageSize="sm" {...mapGeneralProps} />
    </CardLinkWrapper>
  )

  const staticMapItem = (
    <CardLinkWrapper key="listing-no-photos-map-view">
      <StaticMap showMarker markerUrl={markerUrl} {...mapGeneralProps} />
    </CardLinkWrapper>
  )

  const streetOrMap = isStreetViewAvailable ? streetViewItem : staticMapItem

  if (!isFullGallery)
    return (
      <div className={carouselClassName}>
        {firstPhoto?.id ? firstPhotoItem : streetOrMap}
      </div>
    )

  return isStreetViewAvailable !== null ? (
    <ScrollSnapCarousel
      ref={slideRef}
      onSlideChange={async function onSlideChange(idx, direction) {
        if (firstInteractionRef.current === true) {
          await yieldOrContinue('idle')
          onFirstInteraction?.()
          firstInteractionRef.current = false
        }

        if (direction) {
          await yieldOrContinue('idle')
          window.eventTracker?.track('click', {
            section: dataTagSection,
            item:
              direction === DIRECTION.NEXT
                ? 'photo_gallery_right_arrow'
                : 'photo_gallery_left_arrow',
          })
        } else {
          await yieldOrContinue('idle')
          window.eventTracker?.track('scroll', {
            section: dataTagSection,
            item: `photo-${idx}`,
          })
        }
      }}
      className={carouselClassName}
      styles={{
        track: styles.track,
        nextBtn: buttonStyles?.btnNext,
        prevBtn: buttonStyles?.btnPrev,
      }}
      data-tag_section={dataTagSection}
      aria-label="Listing Card Gallery"
      name={`${listingId}-listing-card`}
    >
      {Boolean(firstPhoto?.id) && firstPhotoItem}
      {isStreetViewAvailable && streetViewItem}
      {staticMapItem}
    </ScrollSnapCarousel>
  ) : (
    <Pulse className={styles.image} />
  )
}

export const ListingCardNoPhotosCarousel = memo(ListingCardNoPhotosCarouselBase)
